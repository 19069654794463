<template>
	<h1>Invoke</h1>

	<SignTransactionLogo>
		<template #image>
			<AddressAvatar :address="props.tx.dApp" />
		</template>

		<template #sub-image>
			<CodeAltIcon />
		</template>
	</SignTransactionLogo>

	<Section>
		<SectionLine>
			<div class="hint">Dapp</div>
			<div>{{ formatAddress(props.tx.dApp, 8) }}</div>
		</SectionLine>

		<SectionLine v-if="props.tx.call?.function" class="field">
			<div class="hint">Callable function</div>
			<div>{{ props.tx.call.function }}</div>
		</SectionLine>

		<SectionLineExpand v-if="props.tx.call?.args" class="field">
			<div class="hint">Args</div>

			<template #expanded>
				<table>
					<tr>
						<th>type</th>
						<th>value</th>
					</tr>
					<tr v-for="(arg, index) of props.tx.call.args" :key="index">
						<td class="table-cell">{{ arg.type }}</td>
						<td class="table-cell">{{ arg.value }}</td>
					</tr>
				</table>
			</template>
		</SectionLineExpand>

		<SectionLine v-if="payments.length > 0" class="field">
			<div class="hint">Payments</div>

			<div class="flex f-column">
				<div v-for="payment of payments" :key="payment.key" class="payment">
					{{ payment.toString(false) }}
				</div>
			</div>
		</SectionLine>

		<slot />
	</Section>
</template>

<script setup lang="ts">
import AssetAmountItem from '@/components/AssetAmountItem.vue';
import ExpandContent from '@/components/ui/ExpandContent.vue';
import Section from '@/components/ui/Section.vue';
import SectionLine from '@/components/ui/SectionLine.vue';
import SectionLineExpand from '@/components/ui/SectionLineCollapse.vue';
import { AssetAmount } from '@/libs/asset-amount';
import { useAssets } from '@/services/useAssets';
import { formatAddress } from '@/utils';
import { type InvokeScriptTransaction } from '@waves/ts-types';
import SignTransactionLogo from './SignTransactionLogo.vue';
import ArrowUpIcon from '@/assets/icons/arrow-up.svg?component';
import CodeAltIcon from '@/assets/icons/code-alt.svg?component';
import AddressAvatar from '@/components/AddressAvatar.vue';

const props = defineProps<{
	tx: InvokeScriptTransaction;
}>();

const paymentAssets = await useAssets((props.tx.payment || []).map((e) => e.assetId));

let payments = paymentAssets.map((asset, index) => {
	console.log(asset, props.tx.payment![index].amount, index);

	return new AssetAmount(asset, props.tx.payment![index].amount);
});

// if (props.tx.payment) {
// 	const paymentsAssets = await useAssets(props.tx.payment.map((e) => e.assetId));

// 	const a = paymentsAssets.map((asset, index) => {
// 		console.log(asset, props.tx.payment![index].amount, index);

// 		return new AssetAmount(asset, props.tx.payment![index].amount);
// 	});
// }
</script>

<style scoped>
.table-container {
	width: 100%;
	overflow-x: auto;
}

.table-cell {
	word-break: break-all;
}

td:first-child {
	white-space: nowrap;
}

.payment {
	background-color: var(--destructive-text-color);
	border-radius: 9999px;
	padding: var(--size-xxxs) var(--size-xs);
	color: var(--button-text-color);
	text-align: center;
}
</style>
