import { useTelegram } from '@/composables/useTelegram';
import EmptyLayout from '@/layouts/EmptyLayout.vue';
import { useWalletStore } from '@/stores/useWalletStore';
import type { TRequest } from '@/types';
import type { Component } from 'vue';
import { type NavigationGuardNext, type RouteLocationNormalized, createRouter, createWebHistory } from 'vue-router';

const needLogin = async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    // const walletStore = useWalletStore();

    // await walletStore.loadPromise;

    // if (!walletStore.isLogin || !walletStore.publicKey) return next({ path: '/' });

    return next();
}

export const history = createWebHistory('/')

declare module 'vue-router' {
    interface RouteMeta {
        //undefinded - default, null - empty
        layout?: Component | null,
        excludeKeepAlive?: string
    }
}

export const router = createRouter({
    history,
    routes: [
        {
            path: '/',
            name: 'Main',
            component: () => import('@/views/MainView/MainView.vue'),
            beforeEnter: async (to, from, next) => {
                const { telegram } = useTelegram();

                const start_param = telegram.WebApp.initDataUnsafe.start_param;

                if (start_param) {
                    try {
                        const data = JSON.parse(atob(start_param)) as TRequest;

                        switch (data.method) {
                            case 'connect_rpc': {
                                const walletStore = useWalletStore();

                                await walletStore.loadPromise;

                                if (!walletStore.isLogin || !walletStore.publicKey) return next();

                                const { id, appName, appUrl, networkByte } = data;

                                return next({
                                    path: '/connect_rpc',
                                    query: {
                                        id,
                                        appName,
                                        appUrl,
                                        networkByte
                                    }
                                })
                            }

                            case 'sign_tx_rpc': {
                                const walletStore = useWalletStore();

                                await walletStore.loadPromise;

                                if (!walletStore.isLogin || !walletStore.publicKey) return next();

                                const { networkByte, id } = data;

                                return next({
                                    path: '/sign_tx_rpc',
                                    query: {
                                        id,
                                        networkByte
                                    }
                                });
                            }

                            case 'sign_msg_rpc': {
                                const walletStore = useWalletStore();

                                await walletStore.loadPromise;

                                if (!walletStore.isLogin || !walletStore.publicKey) return next();

                                const { networkByte, id } = data;

                                return next({
                                    path: '/sign_msg_rpc',
                                    query: {
                                        id,
                                        networkByte
                                    }
                                });
                            }
                        }
                    } catch (error) {
                        console.error(`start params must be JSON encoded base64`);
                        console.error(error);

                        return next();
                    }
                } else {
                    next();
                }
            }
        },
        {
            path: '/import',
            name: 'Import',
            component: () => import('@/views/ImportView.vue')
        },
        {
            path: '/create',
            name: 'Create',
            component: () => import('@/views/CreateView.vue')
        },
        {
            path: '/connect_rpc',
            name: 'Connect',
            component: () => import('@/views/ConnectRpcView.vue'),
            beforeEnter: [needLogin]
        },
        {
            path: '/sign_tx_rpc',
            name: 'Sign transaction',
            component: () => import('@/views/SignTransactionRpcView.vue'),
            beforeEnter: [needLogin]
        },
        {
            path: '/sign_msg_rpc',
            name: 'Sign message',
            component: () => import('@/views/SignMessageRpcView.vue'),
            beforeEnter: [needLogin]
        },
        {
            path: '/swap',
            name: 'Swap',
            component: () => import('@/views/SwapView/SwapView.vue'),
            beforeEnter: [needLogin]
        },
        {
            path: '/settings',
            name: 'Settings',
            component: () => import('@/views/SettingsView.vue'),
            beforeEnter: [needLogin],
        },
        {
            path: '/transfer',
            name: 'Transfer',
            component: () => import('@/views/TransferView.vue'),
            beforeEnter: [needLogin]
        },
        {
            path: '/apps',
            name: 'Apps',
            component: () => import('@/views/AppsView.vue'),
            beforeEnter: [needLogin]
        },
        {
            path: '/unsupported',
            name: 'Unsupported',
            component: () => import('@/views/UnsupportedView.vue'),
            meta: {
                layout: EmptyLayout
            }
        },
        {
            path: '/asset/:assetId',
            name: 'Asset',
            component: () => import('@/views/AssetView.vue'),
            meta: {
                excludeKeepAlive: 'AssetView'
            }
        }
        ,
        {
            path: '/receive',
            name: 'Receive',
            component: () => import('@/views/ReceiveView.vue'),
        }
    ]
});

router.onError(error => {
    console.error('router error', error)
});