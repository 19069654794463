<template>
	<!-- <AppLoadBar /> -->
	<RouterView v-slot="{ Component, route }">
		<template v-if="Component">
			<!-- <Transition mode="out-in"> -->

			<component :is="route.meta.layout || AuthLayout">
				<KeepAlive :exclude="excludedRoutes">
					<Suspense timeout="0">
						<component :is="Component" />
						<template #fallback><div class="loading-animation"></div></template>
					</Suspense>
				</KeepAlive>
			</component>

			<!-- </Transition> -->
		</template>
	</RouterView>
	<AppModal />
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { RouterView, useRouter } from 'vue-router';
import AppModal from '@/components/AppModal.vue';
import AuthLayout from '@/layouts/AuthLayout.vue';
import { useTelegram } from './composables/useTelegram';
import { WEBAPP_API_MIN_VERSION } from './constants';

const { telegram } = useTelegram();
const router = useRouter();

if (!telegram.WebApp.isVersionAtLeast(WEBAPP_API_MIN_VERSION)) {
	router.replace('/unsupported');
}

try {
	telegram.WebApp.expand();
	telegram.WebApp.disableVerticalSwipes();
	telegram.WebApp.setHeaderColor(getComputedStyle(document.body).getPropertyValue('--background-color'));

	if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
		document.body.style.setProperty('--safe-area-inset-bottom', '34px');
	}
} catch (error) {
	console.error(error);
}

const excludedRoutes = computed(() => {
	return router
		.getRoutes()
		.filter((route) => typeof route.meta.excludeKeepAlive === 'string')
		.map((route) => route.meta.excludeKeepAlive!);
});
</script>
