<template>
	<div class="asset-amount" @click="modal.show()">
		<AssetAvatar :asset="selectedAssetAmount.asset" class="avatar" />
		<span>{{ selectedAssetAmount.asset.toString() }}</span>
		<ChevronDownIcon class="icon chevron" />
	</div>
</template>

<script setup lang="ts">
import { defineModel, defineProps } from 'vue';
import { Asset, WAVES } from '@/libs/asset';
import { AssetAmount } from '@/libs/asset-amount';
import AssetItem from '@/components/AssetItem.vue';
import AssetAmountItem from '@/components/AssetAmountItem.vue';
import SelectAssetAmountModal from '@/components/modals/SelectAssetAmountModal.vue';
import { useModal } from '@/stores/useModalStore';
import ChevronDownIcon from '@/assets/icons/chevron-down.svg';
import AssetAvatar from '../AssetAvatar.vue';

const props = defineProps<{
	assetAmounts: AssetAmount[];
}>();

const selectedAssetAmount = defineModel<AssetAmount>({
	default: new AssetAmount(new Asset(WAVES), 0)
});

const modal = useModal({
	component: SelectAssetAmountModal,
	props: {
		assetAmounts: props.assetAmounts
	},
	onClose(assetAmount?: AssetAmount) {
		if (assetAmount) selectedAssetAmount.value = assetAmount;
	}
});
</script>

<style scoped>
/* .chevron {
	min-width: var(--size-m);
	min-height: var(--size-m);

	max-width: var(--size-m);
	max-height: var(--size-m);

	margin-left: auto;

	transform: scale(1.4);
} */

.chevron {
	position: absolute;
	right: var(--size-xs);
}

.asset-amount {
	display: flex;
	gap: var(--size-xxs);
	align-items: center;
	padding: var(--size-s) var(--size-xl) var(--size-s) var(--size-s);
	border-radius: var(--size-xs);
	background-color: var(--secondary-background-color);
	overflow: hidden;
	position: relative;
	cursor: pointer;
	text-wrap: nowrap;
}

.asset-amount span {
	overflow: hidden;
}

.avatar {
	height: var(--size-m);
	aspect-ratio: 1;
}
</style>
